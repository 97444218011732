import React from "react"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"
import Layout from "../components/layout"
import YellowDots from "../../static/images/explore-yellow-dots.svg"
import PinkLine from "../../static/images/explore-pink-line.svg"
import YellowDot from "../../static/images/explore-yellow-dot.svg"
import BlueDots from "../../static/images/explore-blue-dots.svg"

const Contact = () => (
  <Layout>
  <Helmet>
    <title>Thank you | IBDmate</title>
  </Helmet>
    <div className="layout-sign-up">
      <div className="purple-bg">
        <div className="content">
          <h1>Thank you</h1>
        </div>
        <div className="vectors-left">
          <img src={YellowDots} id="yellow-dots" alt="" />
          <img src={PinkLine} id="pink-line" alt="" />
        </div>
        <div className="vectors-right">
          <img src={YellowDot} id="yellow-dot" alt="" />
          <img src={BlueDots} id="blue-dots" alt="" />
        </div>
      </div>
      <div className="white-bg">
        <div className="wrapper">
          <div className="thank-you">
            <p>Thank you for your message.</p>
            <p>We will be in touch very soon.</p>
            <Link to="/contact/" className="btn btn-purple">Back to contact page</Link>
          </div>
        </div>
      </div>
    </div>
  </Layout>
  )

  export default Contact
